<template>
  <div id="root"></div>
  <v-app>
    <TheHeader />
    <client-only>
      <v-main
        class="position-relative main-container"
        :class="{'remove-padding' : $route.path === '/'}"
      >
        <v-snackbar
          v-model="authStore.pushNotificationResponse"
          :timeout="4000"
          color="success"
        >
          <div class="text-center">
            {{ authStore.pushNotificationResponse }}
          </div>
        </v-snackbar>
        <div class="position-fixed bottom-1 right-1 z-2">
          <NotificationPushNotification
            v-if="
              authStore.pushNotificationData &&
              authStore.pushNotificationData.type !==
                'payment-direct-session' &&
              authStore.pushNotificationData.type !== 'scheduled-session-time'
            "
          />
        </div>
        <SharedWaitingAlert v-if="authStore.sessionTimer" />
        <SessionsDirectSessionStatusCard
          v-if="
            authStore.pushNotificationData &&
            (authStore.pushNotificationData.type === 'direct-session-request' || authStore.pushNotificationData.type === 'free-direct-session-request')
            && authStore.pushNotificationData.status !== 'pending'
          "
          @close="onStatusDialogClosed"
        />
        <SessionsEvaluationDialog
          :show="showRateDialog"
          @close="showRateDialog = false"
        />
        <NotificationActivePWA :show="showPwaInstructions" :message="instructionsMessage" />
        <NuxtPage />
      </v-main>
    </client-only>
    <TheFooter />
  </v-app>
</template>

<script setup>
import { useAuthStore } from '~~/stores/AuthStore';
import { useSessionStore } from '~~/stores/SessionStore';
const authStore = useAuthStore();
const sessionStore = useSessionStore();

const route = useRoute();
const showRateDialog = ref(false);
const showPwaInstructions = ref(false);
const instructionsMessage = ref('')

watch(
  () => sessionStore.currentSessionId,
  (newValue) => {
    if (newValue && sessionStore.needsRating && !showRateDialog.value) {
      showRateDialog.value = true;
    }
    if(newValue && !sessionStore.needsRating && route.name !== 'room'){
      navigateTo('/room')
    }
  },
  { deep: true }
);

if(sessionStore.currentSessionId && !sessionStore.needsRating && route.name !== 'room'){
      navigateTo('/room')
    }


onMounted(() => {
  if (sessionStore.currentSessionId && sessionStore.needsRating) {
    showRateDialog.value = true;
  }

  const isPWA = window.matchMedia("(display-mode: standalone)").matches;

  if(!isPWA && isIOSGreaterThan16_5()){
  showPwaInstructions.value = true
}

});

function onStatusDialogClosed() {
  const { removeValue } = useLocalStorage();
  removeValue('waitingForRespond');
  authStore.pushNotificationData = null;
}
watch(
  () => authStore.pushNotificationResponse,
  () => {
    setTimeout(() => {
      authStore.pushNotificationResponse = null;
    }, 3000);
  },
  { deep: true }
);


function isIOSGreaterThan16_5() {
    const userAgent = navigator.userAgent;
    const match = userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);

    if (match) {
        const major = parseInt(match[1], 10);
        const minor = parseInt(match[2], 10);
        const patch = match[3] ? parseInt(match[3], 10) : 0;

        // Check if the version is greater than 16.5
        if (major > 16 || (major === 16 && minor > 5)) {
            return true;
        }
        else {
          instructionsMessage.value = 'يرجى تحديث نظامك لIOS 16.5 أو أعلى للحصول على إشعارات الموقع.'
          return true;
        }
    }

    return false;
}

</script>
<style lang="scss">
.v-main {
  min-height: 80vh !important;
  overflow: hidden;
}

.main-container {
  padding: 20vh 10vw 15vh;
  @media(max-width:400px){
    padding: 20vh 1vw 15vh;
  }
}
.remove-padding {
  padding: 0 !important;
}
.bottom-1 {
  bottom: 100px;
}
.right-1 {
  right: 100px;
}
.z-2 {
  z-index: 2;
}
</style>
